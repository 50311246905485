export const NOW = 'NOW'

export const USERNAME = 'USERNAME'
export const ACCOUNT = 'ACCOUNT'
export const VENDOR = 'VENDOR'

export const NOTIFICATIONS = 'NOTIFICATIONS'

export const COUNT_NEW_MESSAGES = 'COUNT_NEW_MESSAGES'

export const SESSIONS = 'SESSIONS'
export const COUNT_NEW_SESSIONS = 'COUNT_NEW_SESSIONS'

export const SESSION = 'SESSION'
export const STEP = 'STEP'
export const LESSON = 'LESSON'
export const ESSAY = 'ESSAY'
export const QUIZ = 'QUIZ'
export const QUESTIONS = 'QUESTIONS'
export const LECTURE = 'LECTURE'
export const MAX_FILE_SIZE = 'MAX_FILE_SIZE'
export const PERMISSIONS = 'PERMISSIONS'
export const IS_OPEN_STEPS = 'IS_OPEN_STEPS'
export const IS_FULL_VIEW = 'IS_FULL_VIEW'

export const MEETINGS = 'MEETINGS'
export const COUNT_NEW_MEETINGS = 'COUNT_NEW_MEETINGS'

export const VENDOR_LIMITS = 'VENDOR_LIMITS'
