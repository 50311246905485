export const UPDATE_NOW = 'UPDATE_NOW'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CREATE_NOTIFICATIONS = 'CREATE_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'
export const UPDATE_COUNT_NEW_MESSAGES = 'UPDATE_COUNT_NEW_MESSAGES'
export const GET_SESSIONS = 'GET_SESSIONS'
export const CONTINUE_SESSION = 'CONTINUE_SESSION'
export const START_SESSION = 'START_SESSION'
export const UPDATE_STEP = 'UPDATE_STEP'
export const SAVE_QUIZ_QUESTION = 'SAVE_QUIZ_QUESTION'
export const TOGGLE_STEPS = 'TOGGLE_STEPS'
export const TOGGLE_VIEW = 'TOGGLE_VIEW'
export const GET_MEETINGS = 'GET_MEETINGS'
export const SAVE_SCORM = 'SAVE_SCORM'
export const GET_VENDOR_LIMITS = 'GET_VENDOR_LIMITS'
